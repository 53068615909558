<style>
	.el-tabs__header {
		background: #fff;
		padding: 0 20px;
	}
	.title {
		color: #fff;
		font-size: 14px;
	}
	.stdname {
		font-size: 13px;
		color: #333;
		margin: 0;
		margin-bottom: 6px;
	}
	.stdtips {
		font-size: 12px;
		color: #999;
		margin: 0;
	}
	.cat {
		box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
		padding: 12px 8px;
		text-align: left;
		color: #fff;
		background: url(../../assets/mask.png) no-repeat 100% 0;
		background-size: auto 60px;
	}
	.cat p {
		text-shadow: 0 2px 12px rgb(0 0 0 / 30%);
	}
</style>
<template>
	<div style="background: linear-gradient(150deg, #2c6dd2 50%, #5eb361);min-height:100%;">
		
		<el-tabs>
			<el-tab-pane label="政策法规" style=" padding:15px;">
				<h4 class="title">法律</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/1.密码法 2019年10月26日发布.pdf')">
					<p class="stdname">《中华人民共和国密码法》</p>
					<p class="stdtips">2019年10月26日 第十三届全国人大第十四次会议通过<br />2020年1月1日起施行</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/网络安全法_2017.6.1.pdf')">
					<p class="stdname">《中华人民共和国网络安全法》</p>
					<p class="stdtips">2016年11月7日 第十二届全国人大第二十四次会议通过<br />2017年6月1日起施行 </p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/电子签名法_20042015.pdf')">
					<p class="stdname">《中华人民共和国电子签名法》</p>
					<p class="stdtips">2004年8月28日 第十届全国人大第十一次会议通过<br />2005年4月1日起施行<br />2019年4月23日 第十三届全国人大第十次会议修订</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/中华人民共和国保守国家秘密法.pdf')">
					<p class="stdname">《中华人民共和国保守国家秘密法》</p>
					<p class="stdtips">1988年9月5日第七届全国人民代表大会常务委员会第三次会议通过<br /> 2010年4月29日第十一届全国人民代表大会常务委员会第十四次会议第一次修订<br /> 2024年2月27日第十四届全国人民代表大会常务委员会第八次会议第二次修订</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/数据安全法-2021.9.1.pdf')">
					<p class="stdname">《中华人民共和国数据安全法》</p>
					<p class="stdtips">2021年6月10日 第十三届全国人大第二十九次会议通过<br />2021年9月1日起施行</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/个人信息保护法.pdf')">
					<p class="stdname">《中华人民共和国个人信息保护法》</p>
					<p class="stdtips">2021年8月20日 第十三届全国人大第三十次会议通过<br />2021年11月1日起施行</p>
				</el-card>
				<h4 class="title">行政法规</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/商用密码管理条例.pdf')">
					<p class="stdname">《商用密码管理条例》</p>
					<p class="stdtips">1999年10月7日发布并施行（国务院令第273号）<br />2020年8月20日 国家密码管理局发布《条例》（征求意见稿)<br />2023年4月14日 国务院第4次常务会议修订通过新版《条例》<br />2023年4月27日 公布新版《条例》（国务院令第760号）<br />2023年7月1日起施行</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/关键信息基础设施安全保护条例.pdf')">
					<p class="stdname">《关键信息基础设施安全保护条例》</p>
					<p class="stdtips">2021年4月27日发布（国务院令第745号发布）<br />2021年9月1日起施行</p>
				</el-card>
				<h4 class="title">部门规章</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/网络安全审查办法_2022.2.15.pdf')">
					<p class="stdname">《网络安全审查办法》</p>
					<p class="stdtips">2020年4月13日发布 （国家网信办、国家发改委、工信部、公安部、国家安全部、财政部、商务部、中国人民银行、国家市场监督管理总局、国家广电总局、中国证监会、国家保密局、国家密码管理局令第8号）<br /> 2022年2月15日起施行</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/国家密码管理局规章制定程序规定.pdf')">
					<p class="stdname">《国家密码管理局规章制定程序规定》</p>
					<p class="stdtips">2021年12月16日国家密码管理局令第1号公布 自2022年2月1日起施行</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/商用密码检测机构管理办法.pdf')">
					<p class="stdname">《商用密码检测机构管理办法》</p>
					<p class="stdtips">2023年9月26日国家密码管理局令第2号公布自2023年11月1日起施行</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/商用密码应用安全性评估管理办法.pdf')">
					<p class="stdname">《商用密码应用安全性评估管理办法》</p>
					<p class="stdtips">2023年9月26日国家密码管理局令第3号公布自2023年11月1日起施行</p>
				</el-card>
			





				<h4 class="title">行政规范性文件</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/信息安全等级保护管理办法.pdf')">
					<p class="stdname">《信息安全等级保护管理办法》</p>
					<p class="stdtips">2007年6月22日 公通字[2007]43号</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/电子认证服务密码管理办法.pdf')">
					<p class="stdname">《电子认证服务密码管理办法》</p>
					<p class="stdtips">2009年10月28日 国家密码管理局公告第17号公布<br />2017年12月1日 修正</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/政务信息系统政府采购管理暂行办法.pdf')">
					<p class="stdname">《政务信息系统政府采购管理暂行办法》</p>
					<p class="stdtips">2017年12月26日 财库〔2017〕210号</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/3.国家政务信息化项目建设管理办法（国办发〔2019〕57号）.pdf')">
					<p class="stdname">《国家政务信息化项目建设管理办法》</p>
					<p class="stdtips">2019年12月30日 国办发〔2019〕57号</p>
				</el-card>
			<!--  
				<el-card shadow="always" style="margin-bottom:12px">
					<p class="stdname">《金融和重要领域密码应用与创新发展工作规划2018-2022年的通知》 </p>
					<p class="stdtips">2018年7月15日 中共中央办公厅、国务院办公厅</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px">
					<p class="stdname">《促进商用密码产业高质量发展的若干措施》 </p>
					<p class="stdtips">2022年1月 国家密码管理局联合中央网信办、国家发改委、科技部、工信部、公安部、财政部、国资委、市场监管总局、证监会</p>
				</el-card>
			-->
			</el-tab-pane>
			<el-tab-pane label="密评规范" style=" padding:15px;">
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/GB_T 43207_2023信息安全技术信息系统密码应用设计指南.pdf')">
					<p class="stdname">GB/T 43207-2023《信息安全技术信息系统密码应用设计指南》（2023）</p>
					<p class="stdtips">2023年09月07日发布<br/>2024年04月01日实施</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/1.GB 39786 基本要求.pdf')">
					<p class="stdname">GB/T 39786 《信息安全技术信息系统密码应用 基本要求》</p>
					<p class="stdtips">2018年 GM/T0054《信息系统密码应用 基本要求》发布<br />2021年3月 国家市场监管总局、国家标准化管理委员会 GB/T 39786发布<br />2021年10月1日起 GB/T 39786实施</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/GB_T 22240-2020 信息安全技术 网络安全等级保护定级指南.pdf')">
					<p class="stdname">GB/T 22240-2020 《信息安全技术 网络安全等级保护定级指南》</p>
					<p class="stdtips">2020年04月28日发布<br />2020年11月01日实施</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/GM_Y 5001_2023密码标准使用指南.pdf')">
					<p class="stdname">GM/Y 5001-2023《密码标准使用指南》</p>
					<p class="stdtips">密码行业标准化技术委员会 <br/> 2023年10月发布</p>
				</el-card>



				<h4 class="title">
					测评指导性文件
				</h4>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/GB_T 43206_2023信息安全技术信息系统密码应用测评要求.pdf')">
					<p class="stdname">1.GB/T 43206-2023《信息安全技术信息系统密码应用测评要求》（2023）</p>
					<p class="stdtips">2023年09月07日发布 <br/>2024年04月01日实施</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/2.GMT 0115测评要求.pdf')">
					<p class="stdname">2.GM/T 0115 《信息系统密码应用 测评要求》</p>
					<p class="stdtips">2021年10月19日批准 2022年5月1日实施</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/3.GMT 0116测评过程指南.pdf')">
					<p class="stdname">3.GM/T 0116《信息系统密码应用 测评过程指南》</p>
					<p class="stdtips">2021年10月19日批准 2022年5月1日实施</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/4.高风险判定指引.pdf')">
					<p class="stdname">4.《信息系统密码应用 高风险判定指引》</p>
					<p class="stdtips">2021年12月17日更新</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/5.量化评估规则.pdf')">
					<p class="stdname">5.《商用密码应用安全性评估量化评估规则》（2023）</p>
					<p class="stdtips">2023年7月17日发布<br>2023年8月1日实施</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/7.方案密评报告报告模板(2023版).pdf')">
					<p class="stdname">6.1《方案密评报告 报告模板》(2023版)</p>
					<p class="stdtips">2023年1月20日更新</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/8.系统密评报告报告模板(2023版).pdf')">
					<p class="stdname">6.2《系统密评报告 报告模板》(2023版)</p>
					<p class="stdtips">2023年1月20日更新</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/FAQ(第三版).pdf')">
					<p class="stdname">7.《商用密码应用安全性评估 FAQ（第三版）》</p>
					<p class="stdtips">2021年12月 第一版发布<br />2022年8月5日 第二版发布<br />2023年10月26日 第三版发布</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/政务信息系统密码应用与安全性评估工作指南.pdf')">
					<p class="stdname">8.《政务信息系统密码应用与安全性评估工作指南(2020版)》</p>
					<p class="stdtips">2020年09月15日 2020版发布</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/政务领域政务云密码应用与安全性评估实施指南.pdf')">
					<p class="stdname">9.《政务领域政务云密码应用与安全性评估实施指南》</p>
					<p class="stdtips">中国密码学会密评联委会<br>2024年4月</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/政务领域政务服务平台密码应用与安全性评估实施指南.pdf')">
					<p class="stdname">10.《政务领域政务服务平台密码应用与安全性评估实施指南》</p>
					<p class="stdtips">中国密码学会密评联委会<br>2024年4月</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/政策法规/GMT 0132-2023  信息系统密码应用实施指南.pdf')">
					<p class="stdname">11.GM/T 0132-2023  《信息系统密码应用实施指南》</p>
					<p class="stdtips">2023</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/商用密码应用安全性评估测评实施指引.pdf')">
					<p class="stdname">12.《商用密码应用安全性评估 测评实施指引》</p>
					<p class="stdtips">2024年11月</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/商用密码应用安全性评估测评工具指引.pdf')">
					<p class="stdname">13.《商用密码应用安全性评估 测评工具指引》</p>
					<p class="stdtips">2024年11月</p>
				</el-card>
				
	
				




				<h4 class="title">
					密码应用方案评估规范（团标）
				</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/团体标准/T_GDCCA 0001-2022 信息系统密码应用方案评估规范.pdf')">
					<p class="stdname">1、T/GDCCA 0001-2022 《信息系统密码应用方案评估规范》</p>
					<p class="stdtips">2022-05-19发布<br>2022-06-01实施</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/团体标准/T_GDCCA 0001-2022 信息系统密码应用方案评估要点.pdf')">
					<p class="stdname">2、T/GDCCA 0001-2022 《信息系统密码应用方案评估要点》</p>
					<p class="stdtips">征求意见稿</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/团体标准/T_GDCCA 0002-2022 信息系统密码应用方案评估过程指南.pdf')">
					<p class="stdname">3、T/GDCCA 0002-2022 《信息系统密码应用方案评估过程指南》</p>
					<p class="stdtips">2022-05-19发布<br>2022-06-01实施</p>
				</el-card>





				
				<h4 class="title">
					其他标准
				</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/GB_T 33560-2017 信息安全技术 密码应用标识规范.pdf')">
					<p class="stdname">1、GB/T 33560-2017 《信息安全技术 密码应用标识规范》</p>
					<p class="stdtips">2017-05-12发布<br>2017-12-01实施</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/国家职业技能标准-密码技术应用员-4-07-05-06.pdf')">
					<p class="stdname">2、国家职业技能标准-密码技术应用员-4-07-05-06</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/国家职业标准-密码工程技术人员-2-02-38-13.pdf')">
					<p class="stdname">3、国家职业标准-密码工程技术人员-2-02-38-13</p>
				</el-card>




				<h4 class="title">
					通知公告
				</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/48家试点目录.pdf')">
					<p class="stdname">《商用密码应用安全性评估试点机构目录》</p>
					<p class="stdtips">2021年6月11日 国家密码管理局公告（第42号） 共48家</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/商用密码产品认证目录（第一批）.pdf')">
					<p class="stdname">《商用密码产品认证目录》（第一批）</p>
					<!--<p class="stdtips">2021年6月11日 国家密码管理局公告（第42号） 共48家</p>-->
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/密评规范/商用密码产品认证目录（第二批）.pdf')">
					<p class="stdname">《商用密码产品认证目录》（第二批）</p>
				<!--	<p class="stdtips">2021年6月11日 国家密码管理局公告（第42号） 共48家</p>-->
				</el-card>
			</el-tab-pane>
	
			



<!--
			<el-tab-pane label="重要行业" style=" padding:15px;">

				<h4 class="title">
					电力行业
				</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/电力行业商用密码应用白皮书.pdf')">
					<p class="stdname">1、电力行业商用密码应用白皮书</p>
					<p class="stdtips">编写单位： 公安部第三研究所、中国电力科学研究院、国网浙江省电力有限公司科学研究院、国网浙江省电力有限公司信息通信分公司、国网浙江省电力有限公司杭州供电公司、杭州市商用密码应用协会、北京信安世纪科技股份有限公司、北京网御星云信息技术有限公司、深信服科技股份有限公司、江南信安（北京）科技有限公司、深圳市东进技术股份有限公司</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/电力行业网络安全管理办法.pdf')">
					<p class="stdname">2、电力行业网络安全管理办法</p>
					<p class="stdtips">国家能源局2022年11月16日印发</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/电力行业网络安全等级保护管理办法.pdf')">
					<p class="stdname">3、电力行业网络安全等级保护管理办法</p>
					<p class="stdtips">国家能源局2022年11月16日印发</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/国家能源局关于加强电力行业网络安全工作的指导意见.pdf')">
					<p class="stdname">4、国家能源局关于加强电力行业网络安全工作的指导意见</p>
					<p class="stdtips">国家能源局2018年9月14日印发</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/电力行业信息系统安全等级保护定级指导意见.pdf')">
					<p class="stdname">5、电力行业信息系统安全等级保护定级指导意见</p>
					<p class="stdtips">国家电力监管委员会 2007年11月</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/国家电网公司信息化“SG186”工程安全防护总体方案(试行).pdf')">
					<p class="stdname">6、国家电网公司信息化“SG186”工程安全防护总体方案(试行)</p>
					<p class="stdtips">2020版</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/电力行业信息安全等级保护基本要求.pdf')">
					<p class="stdname">7、DL/T 电力行业信息安全等级保护基本要求（征求意见稿）</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/GB_T 36047-2018 电力信息系统安全检查规范.pdf')">
					<p class="stdname">8、GB_T 36047-2018 电力信息系统安全检查规范</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/GB_T 36572-2018 电力监控系统网络安全防护导则.pdf')">
					<p class="stdname">9、GB_T 36572-2018 电力监控系统网络安全防护导则</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/GB_T 37138-2018 电力信息系统安全等级保护实施指南.pdf')">
					<p class="stdname">10、GB_T 37138-2018 电力信息系统安全等级保护实施指南</p>
					<p class="stdtips"></p>
				</el-card>
		


				<h4 class="title">
					金融行业
				</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0255-2022  金融行业信息系统商用密码应用 基本要求.pdf')">
					<p class="stdname">1、JR_T 0255-2022  金融行业信息系统商用密码应用 基本要求</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0256-2022  金融行业信息系统商用密码应用 测评要求.pdf')">
					<p class="stdname">2、JR_T 0256-2022  金融行业信息系统商用密码应用 测评要求</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0257-2022  金融行业信息系统商用密码应用 测评过程指南.pdf')">
					<p class="stdname">3、JR_T 0257-2022  金融行业信息系统商用密码应用 测评过程指南</p>
					<p class="stdtips"></p>
				</el-card>
				
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0071.1-2020  金融行业网络安全等级保护实施指引 第1部分：基础和术语.pdf')">
					<p class="stdname">4、JR_T 0071.1-2020  金融行业网络安全等级保护实施指引 第1部分：基础和术语</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0071.2-2020  金融行业网络安全等级保护实施指引 第2部分：基本要求.pdf')">
					<p class="stdname">5、JR_T 0071.2-2020  金融行业网络安全等级保护实施指引 第2部分：基本要求</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0071.3-2020  金融行业网络安全等级保护实施指引 第3部分：岗位能力要求和评价指引.pdf')">
					<p class="stdname">6、JR_T 0071.3-2020  金融行业网络安全等级保护实施指引 第3部分：岗位能力要求和评价指引</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0071.4-2020  金融行业网络安全等级保护实施指引 第4部分：培训指引.pdf')">
					<p class="stdname">7、JR_T 0071.4-2020  金融行业网络安全等级保护实施指引 第4部分：培训指引</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0071.5-2020  金融行业网络安全等级保护实施指引 第5部分：审计要求.pdf')">
					<p class="stdname">8、JR_T 0071.5-2020  金融行业网络安全等级保护实施指引 第5部分：审计要求</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0071.6-2020  金融行业网络安全等级保护实施指引 第6部分：审计指引.pdf')">
					<p class="stdname">9、JR_T 0071.6-2020  金融行业网络安全等级保护实施指引 第6部分：审计指引</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0072-2020  金融行业网络安全等级保护测评指南.pdf')">
					<p class="stdname">10、JR_T 0072-2020  金融行业网络安全等级保护测评指南</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0197-2020 金融数据安全 数据安全分级指南.pdf')">
					<p class="stdname">11、JR_T 0197-2020 金融数据安全 数据安全分级指南</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0198-2020 金融科技创新应用测试规范.pdf')">
					<p class="stdname">12、JR_T 0198-2020 金融科技创新应用测试规范</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0199-2020 金融科技创新安全通用规范.pdf')">
					<p class="stdname">13、JR_T 0199-2020 金融科技创新安全通用规范</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0200-2020 金融科技创新风险监控规范.pdf')">
					<p class="stdname">14、JR_T 0200-2020 金融科技创新风险监控规范</p>
					<p class="stdtips"></p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/JR_T 0171-2020 个人金融信息保护技术规范.pdf')">
					<p class="stdname">15、JR_T 0171-2020 个人金融信息保护技术规范</p>
					<p class="stdtips"></p>
				</el-card>
			


			


				<h4 class="title">
					电子政务
				</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/重要行业/电子政务信息安全等级保护实施指南(试行).pdf')">
						<p class="stdname">1、电子政务信息安全等级保护实施指南(试行)</p>
						<p class="stdtips">国务院信息化工作办公室 2005年9月</p>
					</el-card>

			</el-tab-pane>
-->

			<el-tab-pane label="商密算法" style="padding:15px;">
				<el-row :gutter="12" style="margin-bottom:12px">
					<el-col :span="8">
						<div style="background-color: rgb(159, 134, 255);" class="cat" @click="change_code_index(0)">
							<p style="font-size:14px;font-weight:600;margin:0">随机数</p>
							<p style="font-size:12px;margin:0">生成</p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color: rgb(0, 122, 225);" class="cat" @click="change_code_index(1)">
							<p style="font-size:14px;font-weight:600;margin:0">ZUC</p>
							<p style="font-size:12px;margin:0">序列密码</p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color: rgb(61, 162, 214);" class="cat" @click="change_code_index(2)">
							<p style="font-size:14px;font-weight:600;margin:0">SM4</p>
							<p style="font-size:12px;margin:0">对称密码</p>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="12" style="margin-bottom:12px">
					<el-col :span="8">
						<div style="background-color: rgb(60, 197, 231);" class="cat" @click="change_code_index(3)">
							<p style="font-size:14px;font-weight:600;margin:0">SM3</p>
							<p style="font-size:12px;margin:0">杂凑算法</p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color: rgb(109, 225, 225);" class="cat" @click="change_code_index(4)">
							<p style="font-size:14px;font-weight:600;margin:0">SM2</p>
							<p style="font-size:12px;margin:0">公钥密码</p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color: rgb(162, 229, 185);" class="cat" @click="change_code_index(5)">
							<p style="font-size:14px;font-weight:600;margin:0">SM9</p>
							<p style="font-size:12px;margin:0">公钥IBC密码</p>
						</div>
					</el-col>
				</el-row>
				<template v-if="code_index==0">
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/6.检测标准-算法合规性-随机数生成/GM_T 0005-2021 随机性检测规范.pdf')">
						<p class="stdname">GM/T0005 《随机性检测规范》(2021)</p>
						<p class="stdtips">2021-10-18发布<br>
											2022-05-01实施</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/6.检测标准-算法合规性-随机数生成/GMT 0062 密码产品随机数检测要求.pdf')">
						<p class="stdname">GM/T0062 《密码产品随机数检测要求》</p>
						<p class="stdtips">2018-05-02发布<br>
											2018-05-02实施</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/6.检测标准-算法合规性-随机数生成/GMT 0103-2021 随机数发生器总体框架.pdf')">
						<p class="stdname">GM/T 0103-2021  《随机数发生器总体框架》</p>
						<p class="stdtips">2021-10-18发布<br>
											2022-05-01实施</p>
					</el-card>
				</template>
				<template v-else-if="code_index==1">
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 33133.1-2016 信息安全技术  祖冲之序列密码算法  第1部分：算法描述.pdf')">
						<p class="stdname">GB/T 33133.1-2016 《信息安全技术  祖冲之序列密码算法  第1部分：算法描述》</p>
						<p class="stdtips">2016-10-13发布<br>
											2017-05-01实施</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 33133.2-2021 信息安全技术 祖冲之序列密码算法 第2部分：保密性算法.pdf')">
						<p class="stdname">GB/T 33133.2-2021 《信息安全技术 祖冲之序列密码算法 第2部分：保密性算法》</p>
						<p class="stdtips">2021-10-11发布<br>
											2022-05-01实施</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 33133.3-2021 信息安全技术 祖冲之序列密码算法 第3部分：完整性算法.pdf')">
						<p class="stdname">GB/T 33133.3-2021 《信息安全技术 祖冲之序列密码算法 第3部分：完整性算法》</p>
						<p class="stdtips">2021-10-11发布<br>
											2022-05-01实施</p>
					</el-card>
				</template>
				<template v-else-if="code_index==2">
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 32907-2016 信息安全技术 SM4分组密码算法.pdf')">
						<p class="stdname">GB/T 32907-2016 《信息安全技术 SM4分组密码算法》</p>
						<p class="stdtips">2016</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GBT 17964 信息安全技术 分组密码算法的工作模式.pdf')">
						<p class="stdname">GB/T 17964 《信息安全技术 分组密码算法的工作模式》</p>
						<p class="stdtips">2021</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 15852.1-2020 信息技术 安全技术 消息鉴别码 第1部分：采用分组密码的机制.pdf')">
						<p class="stdname">GB/T 15852.1-2020 《信息技术 安全技术 消息鉴别码 第1部分：采用分组密码的机制》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 36624-2018 信息技术 安全技术 可鉴别的加密机制.pdf')">
						<p class="stdname">GB_T 36624-2018 《信息技术 安全技术 可鉴别的加密机制》</p>
						<p class="stdtips"></p>
					</el-card>




				</template>
				<template v-else-if="code_index==3">
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 32905-2016 信息安全技术 SM3密码杂凑算法.pdf')">
						<p class="stdname">1.GB/T 32905-2016 《信息安全技术 SM3密码杂凑算法》</p>
						<p class="stdtips">2017</p>
					</el-card>
				</template>
				<template v-else-if="code_index==4">
<!--
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0003.1-2012 SM2椭圆曲线公钥密码算法第1部分：总则.pdf')">
						<p class="stdname">GM/T 0003.1-2012 SM2椭圆曲线公钥密码算法第1部分：总则</p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0003.2-2012 SM2椭圆曲线公钥密码算法第2部分：数字签名算法.pdf')">
						<p class="stdname">GM/T 0003.2-2012 SM2椭圆曲线公钥密码算法第2部分：数字签名算法</p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0003.3-2012 SM2椭圆曲线公钥密码算法第3部分：密钥交换协议.pdf')">
						<p class="stdname">GM/T 0003.3-2012 SM2椭圆曲线公钥密码算法第3部分：密钥交换协议</p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0003.4-2012 SM2椭圆曲线公钥密码算法第4部分：公钥加密算法.pdf')">
						<p class="stdname">GMT 0003.4-2012 SM2椭圆曲线公钥密码算法第4部分：公钥加密算法</p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0003.5-2012 SM2椭圆曲线公钥密码算法第5部分：参数定义.pdf')">
						<p class="stdname">GMT 0003.5-2012 SM2椭圆曲线公钥密码算法第5部分：参数定义</p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0009 SM2密码算法使用规范 .pdf')">
						<p class="stdname">GM/T0009 SM2密码算法使用规范 </p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0010 SM2 密码算法加密签名消息语法规范.pdf')">
						<p class="stdname">GM/T0010 SM2密码算法加密签名消息语法规范</p>
						<p class="stdtips">2012年</p>
					</el-card>
-->
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 32918.1-2016 信息安全技术  SM2椭圆曲线公钥密码算法  第1部分：总则.pdf')">
						<p class="stdname">1.GB/T 32918.1-2016 《信息安全技术  SM2椭圆曲线公钥密码算法  第1部分：总则》</p>
						<p class="stdtips">2017</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 32918.2-2016 信息安全技术  SM2椭圆曲线公钥密码算法  第2部分：数字签名算法.pdf')">
						<p class="stdname">2.GB/T 32918.2-2016 《信息安全技术  SM2椭圆曲线公钥密码算法  第2部分：数字签名算法》</p>
						<p class="stdtips">2017</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 32918.3-2016 信息安全技术  SM2椭圆曲线公钥密码算法  第3部分：密钥交换协议.pdf')">
						<p class="stdname">3.GB/T 32918.3-2016 《信息安全技术  SM2椭圆曲线公钥密码算法  第3部分：密钥交换协议》</p>
						<p class="stdtips">2017</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 32918.4-2016 信息安全技术  SM2椭圆曲线公钥密码算法  第4部分：公钥加密算法.pdf')">
						<p class="stdname">4.GB/T 32918.4-2016 《信息安全技术  SM2椭圆曲线公钥密码算法  第4部分：公钥加密算法》</p>
						<p class="stdtips">2017</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 32918.5-2017 信息安全技术 SM2椭圆曲线公钥密码算法 第5部分：参数定义.pdf')">
						<p class="stdname">5.GB/T 32918.5-2017 《信息安全技术 SM2椭圆曲线公钥密码算法 第5部分：参数定义》</p>
						<p class="stdtips">2017</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0009-2023  SM2密码算法使用规范.pdf')">
						<p class="stdname">6.GM/T 0009-2023  《SM2密码算法使用规范》</p>
						<p class="stdtips">2023</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0010-2023  SM2密码算法加密签名消息语法规范.pdf')">
						<p class="stdname">7.GM/T 0010-2023  《SM2密码算法加密签名消息语法规范》</p>
						<p class="stdtips">2023</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T_16262.1-2006_信息技术_抽象语法记法一(ASN.1)_第1部分_基本记法规范.pdf')">
						<p class="stdname">8.GB/T 16262.1-2006《信息技术_抽象语法记法一(ASN.1)_第1部分_基本记法规范》</p>
						<p class="stdtips">2006</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T_1988-1998_信息技术-信息交换用七位编码字符集.pdf')">
						<p class="stdname">9.GB/T 1988-1998《信息技术 信息交换用七位编码字符集》</p>
						<p class="stdtips">1998</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0130-2023  基于SM2算法的无证书及隐式证书公钥机制.pdf')">
						<p class="stdname">10.GM/T 0130-2023  《基于SM2算法的无证书及隐式证书公钥机制》</p>
						<p class="stdtips">2023</p>
					</el-card>


				</template>



				<template v-else-if="code_index==5">
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 38635.1-2020 信息安全技术 SM9标识密码算法 第1部分：总则.pdf')">
						<p class="stdname">1.GB/T 38635.1-2020 《信息安全技术 SM9标识密码算法 第1部分：总则》</p>
						<p class="stdtips">2020年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 38635.2-2020 信息安全技术 SM9标识密码算法 第2部分：算法.pdf')">
						<p class="stdname">2.GB/T 38635.2-2020 《信息安全技术 SM9标识密码算法 第2部分：算法》</p>
						<p class="stdtips">2020年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0044.1-2016 SM9标识密码算法 第一部分：总则.pdf')">
						<p class="stdname">3.GM/T 0044.1-2016 《SM9标识密码算法 第一部分：总则》</p>
						<p class="stdtips">2016年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0044.2-2016 SM9标识密码算法 第二部分：数字签名算法.pdf')">
						<p class="stdname">4.GM/T 0044.2-2016 《SM9标识密码算法 第二部分：数字签名算法》</p>
						<p class="stdtips">2016年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GM∕T 0044.3-2016 SM9标识密码算法 第三部分：密钥交换协议.pdf')">
						<p class="stdname">5.GM∕T 0044.3-2016 《SM9标识密码算法 第三部分：密钥交换协议》</p>
						<p class="stdtips">2016年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0044.4-2016 SM9标识密码算法 第四部分：密钥封装机制和公钥加密算法.pdf')">
						<p class="stdname">6.GM/T 0044.4-2016 《SM9标识密码算法 第四部分：密钥封装机制和公钥加密算法》</p>
						<p class="stdtips">2016年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0044.5-2016 SM9标识密码算法 第五部分：参数定义.pdf')">
						<p class="stdname">7.GM/T 0044.5-2016 《SM9标识密码算法 第五部分：参数定义》</p>
						<p class="stdtips">2016年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GB_T 41389-2022 信息安全技术 SM9密码算法使用规范.pdf')">
						<p class="stdname">8.GB/T 41389-2022 《信息安全技术 SM9密码算法使用规范》</p>
						<p class="stdtips">2022年</p>
					</el-card>
				</template>
				<!-- <h4 class="title">2.算法实现</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0057 基于IBC技术的身份鉴别规范.pdf')">
					<p class="stdname">GM/T0057 基于IBC技术的身份鉴别规范</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/7.检测标准-算法合规性-算法实现/GMT 0067 基于数字证书的身份鉴别接口规范.pdf')">
					<p class="stdname">GM/T0067 基于数字证书的身份鉴别接口规范</p> -->
				<!-- </el-card> -->
			</el-tab-pane>
			<el-tab-pane label="密码产品" style="padding:15px;">
				<el-row :gutter="12" style="margin-bottom:12px">
					<el-col :span="8">
						<div style="background-color:rgb(159, 134, 255);" class="cat" @click="change_product_index(11)">
							<p style="font-size:14px;font-weight:600;margin:0">检测认证体系</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color:rgb(0, 122, 225);" class="cat" @click="change_product_index(9)">
							<p style="font-size:14px;font-weight:600;margin:0">安全等级</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color:rgb(61, 162, 214);" class="cat" @click="change_product_index(10)">
							<p style="font-size:14px;font-weight:600;margin:0">初始工厂检查</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="12" style="margin-bottom:12px">
					<el-col :span="8">
						<div style="background-color:rgb(60, 197, 231);" class="cat" @click="change_product_index(0)">
							<p style="font-size:14px;font-weight:600;margin:0">智能IC卡</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color:rgb(109, 225, 225);" class="cat" @click="change_product_index(1)">
							<p style="font-size:14px;font-weight:600;margin:0">智能密码钥匙</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color:rgb(162, 229, 185);" class="cat" @click="change_product_index(2)">
							<p style="font-size:14px;font-weight:600;margin:0">密码机</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="12" style="margin-bottom:12px">
					<el-col :span="8">
						<div style="background-color:rgb(254, 219, 103);" class="cat" @click="change_product_index(3)">
							<p style="font-size:14px;font-weight:600;margin:0">VPN</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color:rgb(253, 159, 131);" class="cat" @click="change_product_index(4)">
							<p style="font-size:14px;font-weight:600;margin:0">电子签章</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color:rgb(249, 115, 148);" class="cat" @click="change_product_index(5)">
							<p style="font-size:14px;font-weight:600;margin:0">动态口令</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="12" style="margin-bottom:12px">
					
					<el-col :span="8">
						<div style="background-color:rgb(159, 134, 255);" class="cat" @click="change_product_index(7)">
							<p style="font-size:14px;font-weight:600;margin:0">证书认证</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color:rgb(0, 122, 225);" class="cat" @click="change_product_index(8)">
							<p style="font-size:14px;font-weight:600;margin:0">其他产品</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
					<el-col :span="8">
						<div style="background-color:rgb(222, 100, 173);" class="cat" @click="change_product_index(6)">
							<p style="font-size:14px;font-weight:600;margin:0">密码协议</p>
							<p style="font-size:12px;margin:0"></p>
						</div>
					</el-col>
				</el-row>

				<template v-if="product_index==0">
					<h4 class="title">1.智能IC卡&智能IC卡密钥管理系统</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/1.智能IC卡&智能IC卡密钥管理系统/GM_T 0107-2021 智能IC卡密钥管理系统基本技术要求.pdf')">
						<p class="stdname">GM/T0107 《智能IC卡密钥管理系统基本技术要求》</p>
						<p class="stdtips">2021年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/1.智能IC卡&智能IC卡密钥管理系统/GMT 0041-2015 智能IC卡密码检测规范.pdf')">
						<p class="stdname">GM/T0041 《智能IC卡密码检测规范》</p>
						<p class="stdtips">2015年</p>
					</el-card>
				</template>
				<template v-else-if="product_index==1">
					<h4 class="title">1.智能密码钥匙</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/2.智能密码钥匙/GB_T 35291-2017 信息安全技术 智能密码钥匙应用接口规范.pdf')">
						<p class="stdname">GB_T 35291-2017 《信息安全技术 智能密码钥匙应用接口规范》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/2.智能密码钥匙/GMT 0016-2023  智能密码钥匙密码应用接口规范.pdf')">
						<p class="stdname">GM/T 0016-2023  《智能密码钥匙密码应用接口规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/2.智能密码钥匙/GMT 0017-2023  智能密码钥匙密码应用接口数据格式规范.pdf')">
						<p class="stdname">GM/T 0017-2023  《智能密码钥匙密码应用接口数据格式规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/2.智能密码钥匙/GMT 0027-2014 智能密码钥匙技术规范.pdf')">
						<p class="stdname">GM/T0027 《智能密码钥匙技术规范》</p>
						<p class="stdtips">2014年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/2.智能密码钥匙/GMT 0048-2016 智能密码钥匙密码检测规范.pdf')">
						<p class="stdname">GM/T0048 《智能密码钥匙密码检测规范》</p>
						<p class="stdtips">2016年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/2.智能密码钥匙/GMT 0063-2018智能密码钥匙密码应用接口检测规范.pdf')">
						<p class="stdname">GM/T0063 《智能密码钥匙密码应用接口检测规范》</p>
						<p class="stdtips">2018年</p>
					</el-card>
				</template>
				<template v-else-if="product_index==2">
					<h4 class="title">1.服务器密码机&PCI-E、PCI密码卡</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/3.服务器密码机&PCI-E、PCI密码卡/GB_T 36322-2018 信息安全技术密码设备应用接口规范.pdf')">
						<p class="stdname">GB_T 36322-2018 《信息安全技术密码设备应用接口规范》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/3.服务器密码机&PCI-E、PCI密码卡/GMT 0018-2023  密码设备应用接口规范.pdf')">
						<p class="stdname">GM/T 0018-2023  《密码设备应用接口规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/3.服务器密码机&PCI-E、PCI密码卡/GMT 0019-2023  通用密码服务接口规范.pdf')">
						<p class="stdname">GMT 0019-2023  《通用密码服务接口规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/3.服务器密码机&PCI-E、PCI密码卡/GMT 0030-2014 服务器密码机技术规范.pdf')">
						<p class="stdname">GM/T0030 《服务器密码机技术规范》</p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/3.服务器密码机&PCI-E、PCI密码卡/GMT 0059-2018服务器密码机检测规范.pdf')">
						<p class="stdname">GM/T0059 《服务器密码机检测规范》</p>
						<p class="stdtips">2014年</p>
					</el-card>
					<h4 class="title">2.签名验签服务器&时间戳服务器</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/4.签名验签服务器&时间戳服务器/GMT 0020-2012 证书应用综合服务接口规范.pdf')">
						<p class="stdname">GM/T0020 《证书应用综合服务接口规范》</p>
						<p class="stdtips">2018年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/4.签名验签服务器&时间戳服务器/GMT 0020-2023  证书应用综合服务接口规范.pdf')">
						<p class="stdname">GM/T 0020-2023  《证书应用综合服务接口规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/4.签名验签服务器&时间戳服务器/GMT 0033-2023  时间戳接口规范.pdf')">
						<p class="stdname">GM/T 0033-2023  《时间戳接口规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/4.签名验签服务器&时间戳服务器/GMT 0060-2018 签名验签服务器检测规范.pdf')">
						<p class="stdname">GM/T0060 《签名验签服务器检测规范》</p>
						<p class="stdtips">2014年</p>
					</el-card>
					<h4 class="title">3.金融数据密码机</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/5.金融数据密码机/GMT 0045-2016 金融数据密码技术规范.pdf')">
						<p class="stdname">GM/T0045 《金融数据密码技术规范》</p>
						<p class="stdtips">2018年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/5.金融数据密码机/GMT 0046-2016 金融数据密码机检测规范.pdf')">
						<p class="stdname">GM/T0046 《金融数据密码机检测规范》</p>
						<p class="stdtips">2016年</p>
					</el-card>
					<h4 class="title">4.云服务器</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/7.云服务器/GM_T 0104-2021 云服务器密码机技术规范.pdf')">
						<p class="stdname">GM/T0104 《云服务器密码机技术规范》</p>
						<p class="stdtips">2016年</p>
					</el-card>
				</template>
				<template v-else-if="product_index==3">
					<h4 class="title">1.IPSec VPN产品、安全网关</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/6.IPSec VPN产品、安全网关/GB_T 36968-2018 信息安全技术 IPSec VPN技术规范.pdf')">
						<p class="stdname">GB/T 36968-2018 《信息安全技术 IPSec VPN技术规范》</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/6.IPSec VPN产品、安全网关/GBT 32922-2016 信息安全技术  IPSec VPN安全接入基本要求与实施指南.pdf')">
						<p class="stdname">GB/T 32922-2016 《信息安全技术 IPSec VPN安全接入基本要求与实施指南》</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/6.IPSec VPN产品、安全网关/GMT 0022-2023  IPSec VPN技术规范.pdf')">
						<p class="stdname">GM/T 0022-2023  《IPSec VPN技术规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/6.IPSec VPN产品、安全网关/GMT 0023-2023  IPSec VPN网关产品规范.pdf')">
						<p class="stdname">GM/T 0023-2023  《IPSec VPN网关产品规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<h4 class="title">2.SSL VPN产品、安全网关</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/7.SSL VPN产品、安全网关/GB_T 38636-2020 信息安全技术 传输层密码协议（TLCP）.pdf')">
						<p class="stdname">GB/T 38636-2020 《信息安全技术 传输层密码协议（TLCP）》</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/7.SSL VPN产品、安全网关/GMT 0128-2023  数据报传输层密码协议规范.pdf')">
						<p class="stdname">GM/T 0128-2023  《数据报传输层密码协议规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/7.SSL VPN产品、安全网关/GMT 0024-2023 SSL VPN技术规范.pdf')">
						<p class="stdname">GM/T 0024-2023 《SSL VPN技术规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/7.SSL VPN产品、安全网关/GMT 0025-2023  SSL VPN网关产品规范.pdf')">
						<p class="stdname">GM/T 0025-2023  《SSL VPN网关产品规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<h4 class="title">3.安全认证网关</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/8.安全认证网关/GMT 0026-2023  安全认证网关产品规范.pdf')">
						<p class="stdname">GMT 0026-2023  《安全认证网关产品规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
				</template>
				<template v-else-if="product_index==4">
					<h4 class="title">1.安全电子签章系统</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/9.安全电子签章系统/GB_T 33190-2016 电子文件存储与交换格式.pdf')">
						<p class="stdname">GB/T 33190-2016 《电子文件存储与交换格式》</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/9.安全电子签章系统/GB_T 33481-2016 党政机关电子印章应用规范.pdf')">
						<p class="stdname">GB/T 33481-2016 《党政机关电子印章应用规范》</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/9.安全电子签章系统/GB_T 38540-2020 信息安全技术 安全电子签章密码技术规范.pdf')">
						<p class="stdname">GB/T 38540-2020 《信息安全技术 安全电子签章密码技术规范》</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/9.安全电子签章系统/GMT 0131-2023  电子签章应用接口规范.pdf')">
						<p class="stdname">GM/T 0131-2023  《电子签章应用接口规范》</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/9.安全电子签章系统/GMT 0031-2014 安全电子签章密码技术规范.pdf')">
						<p class="stdname">GM/T0031 《安全电子签章密码技术规范》</p>
						<p class="stdtips">2014年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/9.安全电子签章系统/GMT 0047-2016 安全电子签章密码检测规范.pdf')">
						<p class="stdname">GM/T0047 《安全电子签章密码检测规范》</p>
						<p class="stdtips">2014年</p>
					</el-card>
				</template>
				<template v-else-if="product_index==5">
					<h4 class="title">1.动态令牌&动态令牌认证系统</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/10.动态令牌&动态令牌认证系统/GMT 0021-2023  动态口令密码应用技术规范.pdf')">
						<p class="stdname">GM/T 0021-2023  《动态口令密码应用技术规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/10.动态令牌&动态令牌认证系统/GMT 0061-2018动态口令密码应用检测规范.pdf')">
						<p class="stdname">GM/T0061 《动态口令密码应用检测规范》</p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/10.动态令牌&动态令牌认证系统/GBT 38556-2020 信息安全技术 动态口令密码应用技术规范.pdf')">
						<p class="stdname">GB/T 38556-2020 《信息安全技术 动态口令密码应用技术规范》</p>
					</el-card>
				</template>
				<template v-else-if="product_index==6">
					<h4 class="title">1.实体鉴别</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 15843.1-2017 信息技术 安全技术 实体鉴别 第1部分：总则.pdf')">
						<p class="stdname">1. GB_T 15843.1-2017 《信息技术 安全技术 实体鉴别 第1部分：总则》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 15843.2-2017 信息技术 安全技术 实体鉴别 第2部分：采用对称加密算法的机制.pdf')">
						<p class="stdname">2. GB_T 15843.2-2017 《信息技术 安全技术 实体鉴别 第2部分：采用对称加密算法的机制》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 15843.3-2023 信息技术 安全技术 实体鉴别 第3部分：采用数字签名技术的机制.pdf')">
						<p class="stdname">3. GB_T 15843.3-2023 《信息技术 安全技术 实体鉴别 第3部分：采用数字签名技术的机制》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 15843.4-2008 信息技术 安全技术 实体鉴别 第4部分：采用密码校验函数的机制.pdf')">
						<p class="stdname">4. GB_T 15843.4-2008 《信息技术 安全技术 实体鉴别 第4部分：采用密码校验函数的机制》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 15843.5-2005 信息技术 安全技术  实体鉴别  第5部分_使用零知识技术的机制.pdf')">
						<p class="stdname">5. GB_T 15843.5-2005 《信息技术 安全技术  实体鉴别  第5部分_使用零知识技术的机制》</p>
						<p class="stdtips"></p>
					</el-card><el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 15843.6-2018 信息技术 安全技术 实体鉴别 第6部分：采用人工数据传递的机制.pdf')">
						<p class="stdname">6. GB_T 15843.6-2018 《信息技术 安全技术 实体鉴别 第6部分：采用人工数据传递的机制》</p>
						<p class="stdtips"></p>
					</el-card>


					<h4 class="title">2.消息鉴别码</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 15852.1-2020 信息技术 安全技术 消息鉴别码 第1部分：采用分组密码的机制.pdf')">
						<p class="stdname">1. GB_T 15852.1-2020 《信息技术 安全技术 消息鉴别码 第1部分：采用分组密码的机制》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 15852.3-2019 信息技术 安全技术 消息鉴别码 第3部分：采用泛杂凑函数的机制.pdf')">
						<p class="stdname">2. GB_T 15852.3-2019 《信息技术 安全技术 消息鉴别码 第3部分：采用泛杂凑函数的机制》</p>
						<p class="stdtips"></p>
					</el-card>




					<h4 class="title">3.密钥管理</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 17901.1-2020 信息技术 安全技术 密钥管理 第1部分：框架.pdf')">
						<p class="stdname">1. GB_T 17901.1-2020 《信息技术 安全技术 密钥管理 第1部分：框架》</p>
						<p class="stdtips"></p>
					</el-card>




					<h4 class="title">4.散列函数</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 18238.1-2000 信息技术 安全技术 散列函数 第1部分概述.pdf')">
						<p class="stdname">1. GB_T 18238.1-2000 《信息技术 安全技术 散列函数 第1部分 概述》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 18238.2-2002 信息技术 安全技术 散列函数 第2部分采用n位块密码的散列函数.pdf')">
						<p class="stdname">2. GB_T 18238.2-2002 《信息技术 安全技术 散列函数 第2部分 采用n位块密码的散列函数》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 18238.3-2002 信息技术 安全技术 散列函数 第3部分专用散列函数.pdf')">
						<p class="stdname">3. GB_T 18238.3-2002 《信息技术 安全技术 散列函数 第3部分 专用散列函数》</p>
						<p class="stdtips"></p>
					</el-card>




					<h4 class="title">5.公钥基础设施</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 20518-2018 信息安全技术 公钥基础设施 数字证书格式.pdf')">
						<p class="stdname">1. GB_T 20518-2018 《信息安全技术 公钥基础设施 数字证书格式》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 20520-2006 信息安全技术 公钥基础设施 时间戳规范.pdf')">
						<p class="stdname">2. GB_T 20520-2006 《信息安全技术 公钥基础设施 时间戳规范》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 21053-2023 信息安全技术 公钥基础设施 PKI系统安全技术要求.pdf')">
						<p class="stdname">3. GB_T 21053-2023 《信息安全技术 公钥基础设施 PKI系统安全技术要求》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 21054-2023 信息安全技术 公钥基础设施 PKI系统安全测评方法.pdf')">
						<p class="stdname">4. GB_T 21054-2023 《信息安全技术 公钥基础设施 PKI系统安全测评方法》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 30272-2021 信息安全技术 公钥基础设施 标准符合性测评.pdf')">
						<p class="stdname">5. GB_T 30272-2021 《信息安全技术 公钥基础设施 标准符合性测评》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 32213-2015 信息安全技术 公钥基础设施 远程口令鉴别与密钥建立规范.pdf')">
						<p class="stdname">6. GB_T 32213-2015 《信息安全技术 公钥基础设施 远程口令鉴别与密钥建立规范》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 35285-2017 信息安全技术 公钥基础设施 基于数字证书的可靠电子签名生成及验证技术要求.pdf')">
						<p class="stdname">7. GB_T 35285-2017 《信息安全技术 公钥基础设施 基于数字证书的可靠电子签名生成及验证技术要求》</p>
						<p class="stdtips"></p>
					</el-card>
					




					<h4 class="title">6.匿名实体鉴别</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 34953.1-2017 信息技术 安全技术 匿名实体鉴别 第1部分：总则.pdf')">
						<p class="stdname">1. GB_T 34953.1-2017 《信息技术 安全技术 匿名实体鉴别 第1部分：总则》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 34953.2-2018 信息技术 安全技术 匿名实体鉴别 第2部分：基于群组公钥签名的机制.pdf')">
						<p class="stdname">2. GB_T 34953.2-2018 《信息技术 安全技术 匿名实体鉴别 第2部分：基于群组公钥签名的机制》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 34953.4-2020 信息技术 安全技术 匿名实体鉴别 第4部分：基于弱秘密的机制.pdf')">
						<p class="stdname">3. GB_T 34953.4-2020 《信息技术 安全技术 匿名实体鉴别 第4部分：基于弱秘密的机制》</p>
						<p class="stdtips"></p>
					</el-card>




					<h4 class="title">7.匿名数字签名</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 38647.1-2020 信息技术 安全技术 匿名数字签名 第1部分：总则.pdf')">
						<p class="stdname">1. GB_T 38647.1-2020 《信息技术 安全技术 匿名数字签名 第1部分：总则》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 38647.2-2020 信息技术 安全技术 匿名数字签名 第2部分：采用群组公钥的机制.pdf')">
						<p class="stdname">2. GB_T 38647.2-2020 《信息技术 安全技术 匿名数字签名 第2部分：采用群组公钥的机制》</p>
						<p class="stdtips"></p>
					</el-card>


					
					<h4 class="title">8.密码应用标识</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/10.密码协议/GB_T 33560-2017 信息安全技术 密码应用标识规范.pdf')">
						<p class="stdname">1. GB_T 33560-2017 《信息安全技术 密码应用标识规范》</p>
						<p class="stdtips"></p>
					</el-card>
					






				</template>
				<template v-else-if="product_index==7">
					<h4 class="title">1.基础设施类</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/12.证书认证系统&证书认证密钥管理系统/基础设施类/GMT 0014-2023  数字证书认证系统密码协议规范.pdf')">
						<p class="stdname">GM/T 0014-2023  数字证书认证系统密码协议规范.pdf</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/12.证书认证系统&证书认证密钥管理系统/基础设施类/GMT 0015-2023  数字证书格式.pdf')">
						<p class="stdname">GM/T 0015-2023  《数字证书格式》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/12.证书认证系统&证书认证密钥管理系统/基础设施类/GB_T 25056-2018 信息安全技术 证书认证系统密码及其相关安全技术规范.pdf')">
						<p class="stdname">GB_T 25056-2018 《信息安全技术 证书认证系统密码及其相关安全技术规范》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/12.证书认证系统&证书认证密钥管理系统/基础设施类/GMT 0034-2014 基于SM2密码算法的证书认证系统密码及其相关安全技术规范.pdf')">
						<p class="stdname">GM/T0034 《基于SM2密码算法的证书认证系统密码及其相关安全技术规范》</p>
						<p class="stdtips">2014年</p>
					</el-card>
					<h4 class="title">2.密码检测类</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/12.证书认证系统&证书认证密钥管理系统/密码检测类/GMT 0037-2014 证书认证系统检测规范.pdf')">
						<p class="stdname">GM/T0037 《证书认证系统检测规范》</p>
						<p class="stdtips">2014年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/12.证书认证系统&证书认证密钥管理系统/密码检测类/GMT 0038-2014 证书认证密钥管理系统检测规范.pdf')">
						<p class="stdname">GM/T0038 《证书认证密钥管理系统检测规范》</p>
						<p class="stdtips">2014年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/12.证书认证系统&证书认证密钥管理系统/密码检测类/GMT 0043-2015 数字证书互操作检测规范.pdf')">
						<p class="stdname">GM/T0043 《数字证书互操作检测规范》</p>
						<p class="stdtips">2015年</p>
					</el-card>
				</template>
				<template v-else-if="product_index==8">
					<h4 class="title">1.安全门禁系统</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/11.安全门禁系统/GMT 0036-2014 采用非接触卡的门禁系统密码应用技术指南.pdf')">
						<p class="stdname">GM/T0036 《采用非接触卡的门禁系统密码应用技术指南》</p>
						<p class="stdtips">2018年</p>
					</el-card>
					<h4 class="title">2.POS密码应用系统、ATM密码应用系统、多功能密码应用互联网终端</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/1.POS密码应用系统、ATM密码应用系统、多功能密码应用互联网终端/JR_T 0025.7-2018 中国金融集成电路（IC）卡规范 第7部分：借记贷记应用安全规范.pdf')">
						<p class="stdname">JR/T 0025.7 《中国金融集成电路（IC）卡规范 第7部分：借记贷记应用安全规范》</p>
						<p class="stdtips">2018年</p>
					</el-card>
					<h4 class="title">3.密码键盘</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/2.密码键盘/GMT 0049-2016 密码键盘检测规范.pdf')">
						<p class="stdname">GM/T0049 《密码键盘检测规范》</p>
						<p class="stdtips">2016年</p>
					</el-card>
					<h4 class="title">4.电子文件密码应用系统</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/3.电子文件密码应用系统/GB_T 31503-2015 信息安全技术 电子文档加密与签名消息语法.pdf')">
						<p class="stdname">GB_T 31503-2015 《信息安全技术 电子文档加密与签名消息语法》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/3.电子文件密码应用系统/GMT 0055-2018电子文件密码应用技术规范.pdf')">
						<p class="stdname">GM/T0055 《电子文件密码应用技术规范》</p>
						<p class="stdtips">2018年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/3.电子文件密码应用系统/GB_T 38541-2020 信息安全技术 电子文件密码应用指南.pdf')">
						<p class="stdname">GB/T 38541-2020 《信息安全技术 电子文件密码应用指南》</p>
						<p class="stdtips">2020年</p>
					</el-card>
					<h4 class="title">5.可信计算密码支撑平台&可信密码模块</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/4.可信计算密码支撑平台&可信密码模块/GB_T 29829-2022 信息安全技术 可信计算密码支撑平台功能与接口规范.pdf')">
						<p class="stdname">GB/T 29829-2022 《信息安全技术 可信计算密码支撑平台功能与接口规范》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/4.可信计算密码支撑平台&可信密码模块/GMT 0011-2023 可信计算 可信密码支撑平台功能与接口规范.pdf')">
						<p class="stdname">GM/T 0011-2023 《可信计算 可信密码支撑平台功能与接口规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/4.可信计算密码支撑平台&可信密码模块/GMT 0012-2012 可信计算 可信密码模块接口规范.PDF')">
						<p class="stdname">GM/T0012 《可信计算 可信密码模块接口规范》</p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/4.可信计算密码支撑平台&可信密码模块/GMT 0013-2012 可信计算 可信密码模块符合性检测规范.pdf')">
						<p class="stdname">GM/T0013 《可信计算 可信密码模块符合性检测规范》</p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/4.可信计算密码支撑平台&可信密码模块/GMT 0058-2018可信计算TCM服务模块接口规范.pdf')">
						<p class="stdname">GM/T0058 《可信计算TCM服务模块接口规范》</p>
						<p class="stdtips">2018年</p>
					</el-card>
					<h4 class="title">6.对称密钥管理产品</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/5.对称密钥管理产品/GMT 0051-2016 密码设备管理 对称密钥管理技术规范.pdf')">
						<p class="stdname">GM/T0051 《密码设备管理 对称密钥管理技术规范》</p>
						<p class="stdtips">2016年</p>
					</el-card>
					<h4 class="title">7.电子标签芯片</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/6.电子标签芯片/GB_T 37033.1-2018 信息安全技术射频识别系统密码应用技术要求 第1部分：密码安全保护框架及安全级别.pdf')">
						<p class="stdname">GB_T 37033.1-2018 《信息安全技术射频识别系统密码应用技术要求 第1部分：密码安全保护框架及安全级别》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/6.电子标签芯片/GB_T 37033.2-2018 信息安全技术 射频识别系统密码应用技术要求 第2部分：电子标签与读写器及其通信密码应用技术要求.pdf')">
						<p class="stdname">GB_T 37033.2-2018 《信息安全技术 射频识别系统密码应用技术要求 第2部分：电子标签与读写器及其通信密码应用技术要求》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/6.电子标签芯片/GB_T 37033.3-2018 信息安全技术射频识别系统密码应用技术要求 第3部分：密钥管理技术要求.pdf')">
						<p class="stdname">GB_T 37033.3-2018 《信息安全技术射频识别系统密码应用技术要求 第3部分：密钥管理技术要求》</p>
						<p class="stdtips"></p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/6.电子标签芯片/GMT 0035-2014 射频识别系统密码应用技术要求.pdf')">
						<p class="stdname">GM/T0035 《射频识别系统密码应用技术要求》</p>
						<p class="stdtips">2014年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/6.电子标签芯片/GMT 0040-2015 射频识别标签模块密码检测准则.pdf')">
						<p class="stdname">GM/T0040 《射频识别标签模块密码检测准则》</p>
						<p class="stdtips">2015年</p>
					</el-card>


					<h4 class="title">8.随机数发生器</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/8.随机数发生器/GM_T 0078-2020 密码随机数生成模块设计指南.pdf')">
						<p class="stdname">GM/T0078 《密码随机数生成模块设计指南》</p>
						<p class="stdtips">2020年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/8.随机数发生器/GM_T 0103-2021 随机数发生器总体框架.pdf')">
						<p class="stdname">GM/T0103 《随机数发生器总体框架》</p>
						<p class="stdtips">2021年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/8.随机数发生器/GM_T 0105-2021 软件随机数发生器设计指南.pdf')">
						<p class="stdname">GM/T0105 《软件随机数发生器设计指南》</p>
						<p class="stdtips">2021年</p>
					</el-card>
					<h4 class="title">9.区块链密码模块</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/9.区块链密码模块/GM_T 0111-2021 区块链密码应用技术要求.pdf')">
						<p class="stdname">GM/T0111 《区块链密码应用技术要求》</p>
						<p class="stdtips">2021年</p>
					</el-card>
					<h4 class="title">10.安全浏览器密码模块</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/10.安全浏览器密码模块/GM_T 0087-2020 浏览器密码应用接口规范.pdf')">
						<p class="stdname">GM/T0087 《浏览器密码应用接口规范》</p>
						<p class="stdtips">2020年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/10.安全浏览器密码模块/GMT 0126-2023  HTML密码应用置标语法.pdf')">
						<p class="stdname">GM/T 0126-2023  《HTML密码应用置标语法》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<h4 class="title">11.移动终端密码模块</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/11.移动终端密码模块/GMT 0127-2023  移动终端密码模块应用接口规范.pdf')">
						<p class="stdname">GM/T 0127-2023  《移动终端密码模块应用接口规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
					<h4 class="title">12.SSH密码协议</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/8.检测标准-商密产品认证目录22+6中的标准/其他商密产品/12.SSH密码协议/GMT 0129-2023  SSH密码协议规范.pdf')">
						<p class="stdname">GM/T 0129-2023  《SSH密码协议规范》</p>
						<p class="stdtips">2023年</p>
					</el-card>
				</template>
				<template v-else-if="product_index==9">
					<h4 class="title">产品检测：安全等级</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/5.检测标准-安全等级/GMT 0008 安全芯片密码检测准则.pdf')">
						<p class="stdname">GM/T0008 《全芯片密码检测准则》</p>
						<p class="stdtips">2012年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/5.检测标准-安全等级/GMT 0028 密码模块安全技术要求.pdf')">
						<p class="stdname">GM/T0028 《密码模块安全技术要求》</p>
						<p class="stdtips">2014年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/5.检测标准-安全等级/GB_T 38625-2020 信息安全技术 密码模块安全检测要求.pdf')">
						<p class="stdname">GB/T 38625-2020 《信息安全技术 密码模块安全检测要求》</p>
						<p class="stdtips">2020年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/5.检测标准-安全等级/GB_T 37092-2018 信息安全技术密码模块安全要求.pdf')">
						<p class="stdname">GB_T 37092-2018 《信息安全技术密码模块安全要求》</p>
						<p class="stdtips"></p>
					</el-card>



				</template>
				<template v-else-if="product_index==10">
					<h4 class="title">产品认证：初始工厂检查</h4>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/9.认证标准-初始工厂检查/GMT 0065-2019商用密码产品生产和保障能力建设规范.pdf')">
						<p class="stdname">GM/T0065 《商用密码产品生产和保障能力建设规范》</p>
						<p class="stdtips">2019年</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/9.认证标准-初始工厂检查/GMT 0066-2019商用密码产品生产和保障能力建设实施指南.pdf')">
						<p class="stdname">GM/T0066 《商用密码产品生产和保障能力建设实施指南》</p>
						<p class="stdtips">2019年</p>
					</el-card>
				</template>
				<template v-else-if="product_index==11">
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/0.关于发布《网络关键设备和网络安全专用产品目录（第一批）》的公告.pdf')">
						<p class="stdname">关于发布《网络关键设备和网络安全专用产品目录（第一批）》的公告</p>
						<p class="stdtips">2017年6月1日 国家互联网信息办公室、工业和信息化部、公安部、国家认证认可监督管理委员会联合发布</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/1.关于调整商用密码产品管理方式的公告.pdf')">
						<p class="stdname">关于调整商用密码产品管理方式的公告</p>
						<p class="stdtips">2019年12月30日 国家密码管理局、市场监管总局联合发布（第39号）</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/2.关于开展商用密码检测认证工作的实施意见.pdf')">
						<p class="stdname">关于开展商用密码检测认证工作的实施意见</p>
						<p class="stdtips">2020年3月26日 市场监管总局、国家密码管理局联合发布（国市监认证〔2020〕50号）</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/3.关于发布《商用密码产品认证目录（第一批）》《商用密码产品认证规则》的公告.pdf')">
						<p class="stdname">关于发布《商用密码产品认证目录（第一批）》《商用密码产品认证规则》的公告</p>
						<p class="stdtips">2020年5月9日 市场监管总局、国家密码管理局联合发布</p>
					</el-card>
					<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/检测认证/4.关于发布《商用密码产品认证目录（第二批）》的公告.pdf')">
						<p class="stdname">关于发布《商用密码产品认证目录（第二批）》的公告</p>
						<p class="stdtips">2022年7月10日 市场监管总局、国家密码管理局联合发布</p>
					</el-card>
				</template>
			</el-tab-pane>
<!-- ////////////////////////////////等保规范//////////////////////////////////////////////// -->

			<el-tab-pane label="等保规范" style=" padding:15px;">
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB_T 22239-2019 信息安全技术  网络安全等级保护基本要求.pdf')">
					<p class="stdname">GB∕T 22239-2019 《信息安全技术  网络安全等级保护基本要求》</p>
					<p class="stdtips">国家市场监督管理总局&nbsp;中国国家标准化管理委员会发布<br>
										2019-05-10发布<br>2019-12-01实施</p>
				</el-card>

				<h4 class="title">
					政策法规
				</h4>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/中华人民共和国网络安全法.pdf')">
					<p class="stdname">《中华人民共和国网络安全法》</p>
					<p class="stdtips">自2017年6月1日起施行</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/中华人民共和国计算机信息系统安全保护条例.pdf')">
					<p class="stdname">《中华人民共和国计算机信息系统安全保护条例》</p>
					<p class="stdtips">1994年2月18日发布实施</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/中华人民共和国信息安全等级保护管理办法.pdf')">
					<p class="stdname">《中华人民共和国信息安全等级保护管理办法》</p>
					<p class="stdtips">2007年12月14日发布</p>
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/网络安全等级保护条例（征求意见稿）.pdf')">
					<p class="stdname">《网络安全等级保护条例（征求意见稿）》</p>
					
				</el-card>
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/网络安全审查办法.pdf')">
					<p class="stdname">《网络安全审查办法》</p>
					<p class="stdtips">自2020年6月1日起施行</p>
				</el-card>


				<h4 class="title">
					测评指导性文件
				</h4>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB 17859-1999 计算机信息系统  安全保护等级划分准则.pdf')">
					<p class="stdname">GB 17859-1999 《计算机信息系统  安全保护等级划分准则》</p>
					<p class="stdtips">1999-09-13发布<br>2001-01-01实施</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB∕T 25058-2019 信息安全技术 网络安全等级保护实施指南.pdf')">
					<p class="stdname">GB∕T 25058-2019 《信息安全技术 网络安全等级保护实施指南》</p>
					<p class="stdtips">2019-08-30发布<br>2020-03-01实施</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB∕T 22240-2020 信息安全技术 网络安全等级保护定级指南.pdf')">
					<p class="stdname">GB∕T 22240-2020 《信息安全技术 网络安全等级保护定级指南》</p>
					<p class="stdtips">2020-04-28发布<br>2020-11-01实施</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB_T 22239-2019 信息安全技术  网络安全等级保护基本要求.pdf')">
					<p class="stdname">GB∕T 22239-2019 《信息安全技术 网络安全等级保护基本要求》</p>
					<p class="stdtips">国家市场监督管理总局&nbsp;中国国家标准化管理委员会发布<br>
										2019-05-10发布<br>2019-12-01实施</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB_T_20271-2006_信息安全技术__信息系统通用安全技术要求.pdf')">
					<p class="stdname">GB/T 20271-2006 《信息安全技术 信息系统通用安全技术要求》</p>
					<p class="stdtips">中华人民共和国国家质量监督检验检疫总局&nbsp;中国国家标准化管理委员会&nbsp;发布<br>
										2006-05-31发布<br>2006-12-01实施</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB∕T 25070-2019 信息安全技术网络安全等级保护安全设计技术要求.pdf')">
					<p class="stdname">GB∕T 25070-2019 《信息安全技术网络安全等级保护安全设计技术要求》</p>
					<p class="stdtips">2019-05-10发布<br>2019-12-01实施</p>
				</el-card>


				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB∕T 28448-2019 信息安全技术网络安全等级保护测评要求.pdf')">
					<p class="stdname">GB∕T 28448-2019 《信息安全技术网络安全等级保护测评要求》</p>
					<p class="stdtips">2019-05-10发布<br>2019-12-01实施</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB∕T 28449-2018 信息安全技术 网络安全等级保护测评过程指南.pdf')">
					<p class="stdname">GB∕T 28449-2018 《信息安全技术 网络安全等级保护测评过程指南》</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB_T_20269-2006_信息安全技术__信息系统安全管理要求.pdf')">
					<p class="stdname">GB/T 20269-2006 《信息安全技术 信息系统安全管理要求》</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB_T_20282-2006_信息安全技术__信息系统安全工程管理要求.pdf')">
					<p class="stdname">GB/T 20282-2006 《信息安全技术 信息系统安全工程管理要求》</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB∕T 36627-2018 信息安全技术 网络安全等级保护测试评估技术指南.pdf')">
					<p class="stdname">GB∕T 36627-2018 《信息安全技术 网络安全等级保护测试评估技术指南》</p>
					<p class="stdtips"> </p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB∕T 36958-2018 信息安全技术 网络安全等级保护安全管理中心技术要求.pdf')">
					<p class="stdname">GB∕T 36958-2018 《信息安全技术 网络安全等级保护安全管理中心技术要求》</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB∕T 36959-2018 信息安全技术 网络安全等级保护测评机构能力要求和评估规范.pdf')">
					<p class="stdname">GB∕T 36959-2018 《信息安全技术 网络安全等级保护测评机构能力要求和评估规范》</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB_T 35273-2020 信息安全技术 个人信息安全规范.pdf')">
					<p class="stdname">GB∕T 35273-2020 《信息安全技术 个人信息安全规范》</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB_T 36326-2018 信息技术 云计算 云服务运营通用要求.pdf')">
					<p class="stdname">GB∕T 36326-2018 《信息技术 云计算 云服务运营通用要求》</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB_T 20984-2022  信息安全技术 信息安全风险评估方法.pdf')">
					<p class="stdname">GB∕T 20984-2022  《信息安全技术 信息安全风险评估方法》</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/GB_T 35274-2023  信息安全技术 大数据服务安全能力要求.pdf')">
					<p class="stdname">GB∕T 35274-2023  《信息安全技术 大数据服务安全能力要求》</p>
					<p class="stdtips"></p>
				</el-card>
				




				<h4 class="title">
					其他政策
				</h4>
			
				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/计算机信息网络国际联网安全保护管理办法.pdf')">
					<p class="stdname">《计算机信息网络国际联网安全保护管理办法》</p>
					<p class="stdtips">1997年12月11日国务院批准　1997年12月16日公安部令第33号发布　根据2011年1月8日《国务院关于废止和修改部分行政法规的决定》修订</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/互联网信息服务管理办法.pdf')">
					<p class="stdname">《互联网信息服务管理办法》</p>
					<p class="stdtips">2000年9月25日</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/国家信息化领导小组关于加强信息安全保障工作的意见.pdf')">
					<p class="stdname">《国家信息化领导小组关于加强信息安全保障工作的意见》</p>
					<p class="stdtips">2003年9月7日</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/国家网络空间安全战略.pdf')">
					<p class="stdname">《国家网络空间安全战略》</p>
					<p class="stdtips">12月27日</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/《关于信息安全等级保护工作的实施意见 公通字[2004]66号》.pdf')">
					<p class="stdname">关于信息安全等级保护工作的实施意见 公通字[2004]66号</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/关于推动信息安全等级保护测评体系建设.pdf')">
					<p class="stdname">关于推动信息安全等级保护测评体系建设</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/贯彻落实网络安全等级保护制度和关键信息基础设施安全保护制度的指导意见-公安部网站.pdf')">
					<p class="stdname">贯彻落实网络安全等级保护制度和关键信息基础设施安全保护制度的指导意见</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/全国人大常委会关于加强网络信息保护的决定_新闻频道_央视网.pdf')">
					<p class="stdname">全国人大常委会关于加强网络信息保护的决定</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/全国人民代表大会常务委员会关于维护互联网安全的决定__2001年第5号国务院公报_中国政府网.pdf')">
					<p class="stdname">全国人民代表大会常务委员会关于维护互联网安全的决定 2001年第5号国务院公报</p>
					<p class="stdtips"></p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/“十四五”国家信息化规划.pdf')">
					<p class="stdname">“十四五”国家信息化规划</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/“十四五”推进国家政务信息化规划.pdf')">
					<p class="stdname">“十四五”推进国家政务信息化规划</p>
				</el-card>

				<el-card shadow="always" style="margin-bottom:12px" @click.native="show_pdf('https://qsm.anmengsoft.com/src/等保规范/“十四五”信息化和工业化深度融合发展规划.pdf')">
					<p class="stdname">“十四五”信息化和工业化深度融合发展规划</p>
				</el-card>
				
			</el-tab-pane>





		</el-tabs>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				code_index: 0,
				product_index: 11,
				// rate_max: 5,
				// rate_colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
				// rate_texts: ["了解", "了解", "熟悉", "熟悉", "重点掌握"],
			};
		},
		//////////////////////
	


	
		//////////////////////
		mounted() {
			document.title = "政策标准数据库";
		},
		methods: {
			change_code_index(index) {
				this.code_index = index;
			},
			change_product_index(index) {
				this.product_index = index;
			},
			show_pdf(pdf_url) {
				this.$router.push({
					path: "/show_pdf",
					query: {
						pdf: pdf_url,
					},
				});
			},
		},
	};
</script>